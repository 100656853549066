import { useTranslation } from "react-i18next";

const useData = () => {
  const [t] = useTranslation("benefits");
  const benefitsData = [
    {
      id: 1,
      titulo: t("benefit-1.title"),
      descripcion: t("benefit-1.text"),
    },
    {
      id: 2,
      titulo: t("benefit-2.title"),
      descripcion: t("benefit-2.text"),
    },
    {
      id: 3,
      titulo: t("benefit-3.title"),
      descripcion: t("benefit-3.text"),
    },
    {
      id: 4,
      titulo: t("benefit-4.title"),
      descripcion: t("benefit-4.text"),
    },
  ];
  const imgTitle = t("hero-img.strong-text");
  const imgText = t("hero-img.text");
  return { benefitsData, imgTitle, imgText };
};

export default useData;
