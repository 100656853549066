import "../styles/footer.css";
import "../../styles/global.css";

import { useTranslation } from "react-i18next";

import logo from "../img/avanza.png";
import iconFacebook from "../img/icon_face.png";
import iconInstagram from "../img/icon_ig.png";
import iconLinkedIn from "../img/icon_in.png";
import iconWhatsApp from "../img/icon_wsp_footer.png";

function Footer() {
  const [t] = useTranslation("footer");
  return (
    <footer className="footer">
      <div className="grupo-1">
        <div className="redes-sociales">
          <hr />
          <a
            href="https://www.facebook.com/avanza.cys"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={iconFacebook} alt="Facebook" />
          </a>
          <a
            href="https://www.instagram.com/avanza.cys"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={iconInstagram} alt="Instagram" />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=51971125128"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={iconWhatsApp} alt="WhatsApp" />
          </a>
          <a
            href="https://www.linkedin.com/company/avanza-consulting-and-solutions"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={iconLinkedIn} alt="LinkedIn" />
          </a>
          <hr />
        </div>
      </div>
      <div className="grupo-2">
        <div className="box-1">
          <p>{t("text")}</p>
        </div>
        <div className="box-2">
          <figure>
            <img src={logo} alt="Logo Avanza" />
          </figure>
        </div>
      </div>
      <div className="grupo-3">
        <small>{t("copyright")}</small>
      </div>
    </footer>
  );
}

export default Footer;
