import "../styles/header.css";
import "../../styles/global.css";

import Title from "./Title";
import Carrousel from "./Carrousel";

export default function Header() {
  return (
    <header className="swiper_banner carrousel_home">
      <Title />
      <Carrousel />
    </header>
  );
}
