import "../styles/clients.css";

import { useTranslation } from "react-i18next";

import logoProInvesting from "../img/pro-investing.png";
import logoAgepaf from "../img/agepaf.png";
import logoAddera from "../img/addera.png";
import logoCalendarCook from "../img/calendar-cook.png";

function Clients() {
  const [t] = useTranslation("clients");
  return (
    <div className="container clientes">
      <h1 className="titulos">{t("title")}</h1>
      <p
        className="contenido"
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        {t("text")}
      </p>

      <div className="container_slider">
        <div className="container_card_cliente">
          <div className="contenido_clientes">
            <img
              src={logoProInvesting}
              className="logos_clientes"
              alt="Pro Investing"
            />
          </div>
          <div className="contenido_clientes">
            <img src={logoAgepaf} className="logos_clientes" alt="Agepaf" />
          </div>
          <div className="contenido_clientes">
            <img src={logoAddera} className="logos_clientes" alt="Addera" />
          </div>
          <div className="contenido_clientes">
            <img
              src={logoCalendarCook}
              className="logos_clientes"
              alt="Calendar Cook"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
