import "../styles/contact.css";

import { useTranslation } from "react-i18next";

import iconPhone from "../img/icon_celu.png";
import iconWhatsApp from "../img/icon_wsp.png";
import iconEmail from "../img/icon_correo.png";

function Contact() {
  const [t] = useTranslation("contact");

  return (
    <div className="container contactos">
      <h1 className="titulos">{t("title")}</h1>
      <p
        className="contenido"
        style={{
          justifyContent: "center",
          textAlign: "center",
          marginBottom: "50px",
        }}
      >
        {t("text")}
      </p>

      <div className="grid_container_contactos">
        {/* Teléfono */}
        <div className="contenido_contactos">
          <div className="container_icon_contactos">
            <div className="circulo_icon_contactos">
              <img
                className="icon_contactos"
                src={iconPhone}
                alt="Icono Teléfono"
              />
            </div>
          </div>
          <div className="texto_contacto">
            <p className="texto_contacto_titulo">{t("call-us")}</p>
            <p>+51 905 448 631</p>
          </div>
          <a className="btn_contactos" href="tel:+51971125128">
            Llamar ahora
          </a>
        </div>

        {/* WhatsApp */}
        <div className="contenido_contactos">
          <div className="container_icon_contactos">
            <div className="circulo_icon_contactos">
              <img
                className="icon_contactos"
                src={iconWhatsApp}
                alt="Icono WhatsApp"
              />
            </div>
          </div>
          <div className="texto_contacto">
            <p className="texto_contacto_titulo">Whatsapp</p>
            <p>+51 905 448 631</p>
          </div>
          <a
            className="btn_contactos"
            href="https://api.whatsapp.com/send/?phone=51905448631"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hablemos
          </a>
        </div>

        {/* Correo */}
        <div className="contenido_contactos">
          <div className="container_icon_contactos">
            <div className="circulo_icon_contactos">
              <img
                className="icon_contactos"
                src={iconEmail}
                alt="Icono Correo"
              />
            </div>
          </div>
          <div className="texto_contacto">
            <p className="texto_contacto_titulo">Email</p>
            <p>info@avanzacys.com</p>
          </div>
          <a className="btn_contactos" href="mailto:avanza.cys@outlook.com">
            Enviar
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
