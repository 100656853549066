import "../styles/services.css";

import { useTranslation } from "react-i18next";

import icon_consultoria from "../img/icon_consultoria.png";
import bg_consultoria from "../img/bg_consultoria.png";
import icon_mobile from "../img/icon_mobile.png";
import bg_mobile from "../img/bg_mobile.png";
import icon_web from "../img/icon_web.png";
import bg_web from "../img/bg_web.png";
import icon_aseguramiento from "../img/icon_aseguramiento.png";
import bg_aseguramiento from "../img/bg_aseguramiento.png";
import icon_nube from "../img/icon_nube.png";
import bg_nube from "../img/bg_nube.png";
import icon_outsourcing from "../img/icon_outsourcing.png";
import bg_outsourcing from "../img/bg_outsourcing.png";
import icon_ui_ux from "../img/icon_ui_ux.png";
import bg_ui_ux from "../img/bg_ui_ux.png";
import icon_bi from "../img/icon_bi.png";
import bg_bi from "../img/bg_bi.png";
import circuloRayas from "../img/circulo_rayas.png";

function Services() {
  const [t] = useTranslation("services");
  const servicioData = [
    {
      name: t("consulting.title"),
      description: t("consulting.text"),
      icon: icon_consultoria,
      backgroundImage: bg_consultoria,
    },
    {
      name: t("mobile.title"),
      description: t("mobile.text"),
      icon: icon_mobile,
      backgroundImage: bg_mobile,
    },
    {
      name: t("web.title"),
      description: t("web.text"),
      icon: icon_web,
      backgroundImage: bg_web,
    },
    {
      name: t("qa.title"),
      description: t("qa.text"),
      icon: icon_aseguramiento,
      backgroundImage: bg_aseguramiento,
    },
    {
      name: t("cloud.title"),
      description: t("cloud.text"),
      icon: icon_nube,
      backgroundImage: bg_nube,
    },
    {
      name: t("outsourcing.title"),
      description: t("outsourcing.text"),
      icon: icon_outsourcing,
      backgroundImage: bg_outsourcing,
    },
    {
      name: t("ux_ui.title"),
      description: t("ux_ui.text"),
      icon: icon_ui_ux,
      backgroundImage: bg_ui_ux,
    },
    {
      name: t("bi.title"),
      description: t("bi.text"),
      icon: icon_bi,
      backgroundImage: bg_bi,
    },
  ];
  return (
    <section className="container servicios">
      <h1 className="titulos">{t("title")}</h1>
      <div className="grid_container_servicios">
        {servicioData.map((servicio, index) => (
          <div key={index} className="container_servicios">
            <div className="circulos4">
              <figure>
                <img src={servicio.icon} alt={servicio.name} />
              </figure>
            </div>
            <p className="subtitulos-servicios">{servicio.name}</p>
            <p className="contenido-servicios">{servicio.description}</p>
            <div className="capa_imagen">
              <img
                src={servicio.backgroundImage}
                className="img_servicios"
                alt=""
              />
            </div>
          </div>
        ))}
      </div>

      <figure className="circulo_raya">
        <img src={circuloRayas} alt="Decorativo circulo con rayas" />
      </figure>
    </section>
  );
}

export default Services;
