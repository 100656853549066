import logo from "../img/logo-avanza.png";
import iconFacebook from "../img/icon_facebook.png";
import iconInstagram from "../img/icon_instagram.png";
import iconWhatsApp from "../img/icon_whatsapp.png";
import iconLinkedIn from "../img/icon_linkedin.png";

import useLanguage from "../../translations/useLanguaje";
import { FlagContext } from "../../App";
import SocialLogos from "./SocialLogos";
import { useContext } from "react";

const facebookUrl = "https://www.facebook.com/avanza.cys";
const instagramUrl = "https://www.instagram.com/avanza.cys";
const whatsappUrl = "https://api.whatsapp.com/send/?phone=51971125128";
const linkedInUrl =
  "https://www.linkedin.com/company/avanza-consulting-and-solutions";

const Title = () => {
  const { language, handleChangeLanguage } = useLanguage("header");
  const { flag } = useContext(FlagContext);

  return (
    <section className="logos_header">
      <img src={logo} className="logo_avanza" alt="Logo Avanza" />
      <div className="redes_sociales_header">
        <SocialLogos
          url={facebookUrl}
          imgSrc={iconFacebook}
          alt="Facebook Icon"
        />
        <SocialLogos
          url={instagramUrl}
          imgSrc={iconInstagram}
          alt="Instagram Icon"
        />
        <SocialLogos
          url={whatsappUrl}
          imgSrc={iconWhatsApp}
          alt="WhatsApp Icon"
        />
        <SocialLogos
          url={linkedInUrl}
          imgSrc={iconLinkedIn}
          alt="LinkedIn Icon"
        />
        <img
          className="flag_img"
          src={flag}
          alt="Flag"
          onClick={() => handleChangeLanguage(language === "es" ? "en" : "es")}
        />
      </div>
    </section>
  );
};

export default Title;
