import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../App";

const useLanguage = (componentName) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const { t, i18n } = useTranslation(componentName);

  const handleChangeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  return { t, language, setLanguage, handleChangeLanguage };
};

export default useLanguage;
