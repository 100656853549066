import React from "react";
import ReactDOM from "react-dom/client";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// translations
import header_en from "./translations/en/header.json";
import header_es from "./translations/es/header.json";
import cards_en from "./translations/en/cards.json";
import cards_es from "./translations/es/cards.json";
import work_en from "./translations/en/how-we-work.json";
import work_es from "./translations/es/how-we-work.json";
import benefits_es from "./translations/es/benefits.json";
import benefits_en from "./translations/en/benefits.json";
import services_es from "./translations/es/services.json";
import services_en from "./translations/en/services.json";
import clients_es from "./translations/es/clients.json";
import clients_en from "./translations/en/clients.json";
import contact_es from "./translations/es/contact.json";
import contact_en from "./translations/en/contact.json";
import footer_es from "./translations/es/footer.json";
import footer_en from "./translations/en/footer.json";

i18next.init({
  interpolation: { escapeValue: true },
  lng: "es",
  resources: {
    en: {
      header: header_en,
      cards: cards_en,
      work: work_en,
      benefits: benefits_en,
      services: services_en,
      clients: clients_en,
      contact: contact_en,
      footer: footer_en,
    },
    es: {
      header: header_es,
      cards: cards_es,
      work: work_es,
      benefits: benefits_es,
      services: services_es,
      clients: clients_es,
      contact: contact_es,
      footer: footer_es,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
