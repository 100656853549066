// import logo from './logo.svg';
import { useState, createContext } from "react";
import useLocalization from "./geolocation/useLocalization";

import Header from "./header/components/Header";
import Cards from "./cards-section/components/Cards";
import HowWeWork from "./how-we-work/components/HowWeWork";
import Benefits from "./benefits/components/benefits";
import Services from "./our-services/components/Services";
import Clients from "./clients/components/Clients";
import Contact from "./contact/components/Contact";
import Footer from "./footer/components/Footer";

export const LanguageContext = createContext({
  language: "es",
});

export const FlagContext = createContext({
  flag: null,
});

function App() {
  const [language, setLanguage] = useState(null);
  const { country, flag } = useLocalization();

  // Wait for country to be fetched before rendering the rest of the app
  if (!country) return null;

  return (
    <div>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <FlagContext.Provider value={{ flag }}>
          <Header />
          <Cards />
          <HowWeWork />
          <Benefits />
          <Services />
          <Clients />
          <Contact />
          <Footer />
        </FlagContext.Provider>
      </LanguageContext.Provider>
    </div>
  );
}

export default App;
