import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCountryName, fetchIpInfo } from "./service";

import usaFlag from "./img/flag-usa.svg";
import peruFlag from "./img/flag-peru.svg";

const countryTable = [
  { country: "Peru", language: "es" },
  { country: "PE", language: "es" },
  { country: "United States", language: "en" },
  { country: "US", language: "en" },
];

const useLocalization = () => {
  const [country, setCountry] = useState(null);
  const [flag, setFlag] = useState(null);
  const [, setLanguage] = useState(null);
  const { i18n } = useTranslation("header");

  useEffect(() => {
    const fetchCountry = async () => {
      const countryName = (await getCountryName()) || (await fetchIpInfo());

      const handleChangeCountry = (countryName) => {
        setCountry(countryName || "US");
        const newLanguage =
          countryTable.find((o) => o.country === countryName)?.language || "en";
        console.info("language:", newLanguage);
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
        setFlag(newLanguage === "es" ? usaFlag : peruFlag);
      };
      handleChangeCountry(countryName);
    };

    fetchCountry();
  }, [i18n, setLanguage]);

  return { country, flag };
};

export default useLocalization;
