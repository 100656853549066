import Geocoder from "react-native-geocoding";

export async function getCountryName() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        Geocoder.init("AIzaSyA2mU_uiYG_Mu-jKAKE5DOvT42uN-zODjI");
        try {
          const json = await Geocoder.from({
            lat: latitude,
            lng: longitude,
          });

          const country = json.results[0].address_components.find((component) =>
            component.types.includes("country")
          );

          if (country) {
            resolve(country.long_name);
          } else {
            reject("Country not found");
          }
        } catch (error) {
          console.error(error);
          reject(error);
        }
      },
      (error) => {
        resolve(null);
      }
    );
  });
}

export async function fetchIpInfo() {
  try {
    const response = await fetch("https://ipinfo.io/json");
    const country = await response.json().country;
    return country;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
