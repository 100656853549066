import "../styles/benefits.css";
import personaTabletImage from "../img/persona_tablet.png";
import medioCirculo from "../img/medio_circulo_hueco.png";

import useData from "./data";

function Benefits() {
  const { benefitsData, imgTitle, imgText } = useData();

  return (
    <div className="container beneficios">
      <div
        className="persona_tablet"
        style={{
          backgroundImage: `url(${personaTabletImage})`,
        }}
      >
        <p className="texto_imagen_beneficio">
          <strong>{imgTitle}</strong>
          {imgText}
        </p>
      </div>
      <div className="grid_container_beneficios">
        {benefitsData.map((beneficio) => (
          <figure key={beneficio.id}>
            <div className="circulos1">
              <div className="circulos2">
                <div className="circulos3">{beneficio.id}</div>
              </div>
            </div>
            <p className="subtitulos">{beneficio.titulo}</p>
            <p className="contenido">{beneficio.descripcion}</p>
          </figure>
        ))}
      </div>
      <div className="circulo_hueco">
        <img src={medioCirculo} alt="Circulo decorativo" />
      </div>
    </div>
  );
}

export default Benefits;
