import "../styles/cards.css";
import "../../styles/global.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";

import transparencia from "../img/transparencia.svg";
import mejoramientoContinuo from "../img/mejoramiento-continuo.svg";
import trabajoEnEquipo from "../img/trabajo-en-equipo.svg";
import profesionalismo from "../img/profesionalismo.svg";
import integridad from "../img/integridad.svg";
import eficiencia from "../img/eficiencia.svg";
import arrowLeft from "../img/flecha_izquierda.png";
import arrowRight from "../img/flecha_derecha.png";

function Cards() {
  const [t] = useTranslation("cards");
  const swiperRef = useRef(null);

  const cardData = [
    { image: transparencia, title: t("transparency.text") },
    { image: mejoramientoContinuo, title: t("improvement.text") },
    { image: trabajoEnEquipo, title: t("teamwork.text") },
    { image: profesionalismo, title: t("professionalism.text") },
    { image: integridad, title: t("integrity.text") },
    { image: eficiencia, title: t("efficiency.text") },
  ];
  useEffect(() => {
    const handleResize = () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.update();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container slider_cont">
      <div className="medio_circulo"></div>
      <div className="cards_swiper_valores container_card">
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={33}
          slidesPerView={1}
          loop={true}
          className="cards_swiper-wrapper"
          autoplay={{
            delay: 700,
            disableOnInteraction: false,
          }}
          speed={600}
          effect="slide" // 'slide', 'fade', 'cube', 'coverflow' o 'flip'
          breakpoints={{
            320: { slidesPerView: 1, navigation: true },
            640: { slidesPerView: 2, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 30 },
            992: { slidesPerView: 3, navigation: true },
            1200: { slidesPerView: 6, navigation: false },
          }}
        >
          {cardData.map((card, index) => (
            <SwiperSlide
              key={index}
              className="cards_card_primary cards_itemCarrouselTarjeta"
            >
              <img src={card.image} alt={card.title} />
              <var>
                <abbr>{card.title}</abbr>
              </var>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="cards_itemCarrouselArrows">
          <div className="cards_swiper-button-prev">
            <img src={arrowLeft} alt="Previous" />
          </div>
          <div className="cards_swiper-button-next">
            <img src={arrowRight} alt="Next" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
