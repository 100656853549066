import "../styles/how-we-work.css";

import { useTranslation } from "react-i18next";

import iconAdaptabilidad from "../img/icon_adaptabilidad.png";
import iconMetodologia from "../img/icon_metodologia.png";
import iconGrupoPersonas from "../img/icon_grupo_personas.png";
import iconComunicacion from "../img/icon_comunicacion.png";
import iconInnovacion from "../img/icon_innovacion.png";
import iconCalidad from "../img/icon_calidad.png";

function HowWeWork() {
  const [t] = useTranslation("work");

  const trabajos = [
    {
      icon: iconAdaptabilidad,
      title: t("adaptability.title"),
      description: t("adaptability.description"),
      alt: "Se muestra un icono de persona con elementos a su alrededor",
    },
    {
      icon: iconMetodologia,
      title: t("mothodology.title"),
      description: t("mothodology.description"),
      alt: "Se muestra una camino que al final tiene un check",
    },
    {
      icon: iconGrupoPersonas,
      title: t("user-fist.title"),
      description: t("user-fist.description"),
      alt: "Se muestra 3 iconos de personas",
    },
    {
      icon: iconComunicacion,
      title: t("communication.title"),
      description: t("communication.description"),
      alt: "Se muestra una lupa y una bombilla",
    },
    {
      icon: iconInnovacion,
      title: t("innovation.title"),
      description: t("innovation.description"),
      alt: "Se muestra la cabeza de una personas con iconos de tuercas",
    },
    {
      icon: iconCalidad,
      title: t("quality.title"),
      description: t("quality.description"),
      alt: "Se muestra una mano recibiendo un símbolo de dinero",
    },
  ];
  return (
    <div className="container how-we-work">
      <h1 className="titulos">
        <strong>{t("title")}</strong>
      </h1>
      <div className="grid_container_work">
        {trabajos.map((trabajo, index) => (
          <div key={index} className="grid-item">
            <img
              className="icons-how-we-work"
              src={trabajo.icon}
              alt={trabajo.alt}
            />
            <p className="subtitulos">{trabajo.title}</p>
            <p className="contenido">{trabajo.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HowWeWork;
