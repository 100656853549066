import "swiper/css";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import useLanguage from "../../translations/useLanguaje";

import arrowLeft from "../img/flecha_izquierda.png";
import arrowRight from "../img/flecha_derecha.png";
import backgroundImage1 from "../img/persona_codigo.png";
import backgroundImage2 from "../img/person_computador.png";
import backgroundImage3 from "../img/codigo.png";

const Carrousel = () => {
  const { t } = useLanguage("header");

  return (
    <>
      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: ".header_swiper-button-next",
          prevEl: ".header_swiper-button-prev",
        }}
        loop={true}
        slidesPerView={1}
        className="swiper-header"
      >
        <SwiperSlide
          style={{
            backgroundImage: `url(${backgroundImage1})`,
            backgroundSize: "cover",
          }}
        >
          <div className="header_itemCarrouselTarjeta">
            <p>
              <b>{t("slider.innovation.strong-text")}</b>
              {t("slider.innovation.text")}
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: `url(${backgroundImage2})`,
            backgroundSize: "cover",
          }}
        >
          <div className="header_itemCarrouselTarjeta">
            <p>
              <b>{t("slider.tailor-made.strong-text")}</b>
              {t("slider.tailor-made.text")}
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: `url(${backgroundImage3})`,
            backgroundSize: "cover",
          }}
        >
          <div className="header_itemCarrouselTarjeta">
            <p>
              <b>{t("slider.challenges.strong-text")}</b>
              {t("slider.challenges.text")}
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
      <section className="itemCarrouselArrows">
        <div className="header_swiper-button-prev">
          <img src={arrowLeft} alt="Previous" />
        </div>
        <div className="header_swiper-button-next">
          <img src={arrowRight} alt="Next" />
        </div>
      </section>
    </>
  );
};

export default Carrousel;
